import React from 'react';
import '../css/main.css';
import '../css/bulma.css';

class Footer extends React.Component {
    render() { 
      return (
        <div className="simple-footer">  
            <h4 className="definition-title">Reducio</h4>
            <h3 className="definition-phonetics">ruh-DOO-see-oh</h3>
            <div className="column definition">
              <p>
                1. To make smaller.
              </p>
              <p>
                2. The Shrinking Charm (Reducio) is a charm that causes an object to shrink. It is the counter-charm to the Engorgement Charm, causing enlarged objects to return to their original size. The Shrinking Charm is also used as the potion-making spell for the Shrinking Solution.
                <a target="_blank" rel="noopener noreferrer" href="https://harrypotter.fandom.com/wiki/Shrinking_Charm" className="link-text"> [src]</a>
              </p>
            </div>
            <p className="definition-source">Hogwarts School of Witchcraft and Wizardry</p>
        </div>
      );
    }
}

export default Footer;

  