import React from 'react';
import Body from './body';
import { ThemeProvider } from 'styled-components';
import { harryTheme } from '../theme';
import { GlobalStyles } from '../global';
import ReactGA from 'react-ga';

function Main() { 
    
    return (
        <ThemeProvider theme={harryTheme}>
            <>
            <GlobalStyles />
            <div className="columns">
                <div className="column is-8 is-offset-2">
                  <Body />
                </div>
            </div>
            <p id="themeID"></p>        
            </>
        </ThemeProvider>
    );
    
}

export default Main;

  