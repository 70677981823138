import React from 'react';
import '../css/main.css';
import '../css/bulma.css';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '../theme';
import { GlobalStyles } from '../global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default class Ooops extends React.Component {

    componentDidMount() {
      this.newFact();  
    }

    newFact() {
        var facts = [
            "J.K. Rowling and Harry Potter have the same birthday, which is July 31.",
            "Dumbledore has a deeper meaning. It's actually an Old English word for “bumblebee.” Rowling has said that she chose that name because she always thought of Dumbledore humming to himself.",
            "Rowling eventually revealed the fate of Dolores Umbridge, although we didn't read about it in the books: she was eventually imprisoned for her actions at the Ministry of Magic and was sent to Azkaban.",
            "The names of some of the main characters were changed from Rowling's original names. Before she changed them, they were: Hermione Puckle, Draco Spinks, Neville Puff, and Lily Moon (who ended up as Luna Lovegood).",
            "Whenever you say Harry, Ron, and Hermione doing schoolwork in the movies, Daniel Radcliffe, Rupert Grint, and Emma Watson were actually doing their homework.",
            "Harry, Ron, and Hermione's names all had deeper meanings too. Harry is the middle-English version of Henry, which was a name popular for English kings (because Harry is a leader). “Ronald” comes from the Old Norse Rögnvaldr, a title for a ruler's adviser (he's Harry's best friend). Hermione's name is from Shakespeare's The Winter's Tale, and was just meant to be clever, since her parents are scholarly.",
            "Robbie Coltrane, who plays Hagrid in the films, was the first actor to be cast in the series.",
            "Rowling credits the Harry Potter books to her mother's death, saying the books have a major death theme in them because she started writing them six months before her mother died. She has said that if she didn't die, there wouldn't be a Harry Potter.",
            "Rowling once said that when she wasn't in a happy place, she almost killed off Ron out of sheer spite.",
            "If you look closely, you'll notice that the scar on Harry's head on the book cover and in the film are different. On the books, the scar is in the middle of his forehead. In the films, it's off-center.",
            "Although Hogwarts is in England and Harry lived in London, much of the series is inspired by Edinburgh, Scotland, which is where Rowling lives.",
            "The Dementors were based off of Rowling's experience with depression. She once described it as “that cold absence of feeling—that really hollowed out feeling. That's what Dementors are.”",
            "Rowling created Quidditch after having a big fight with a now ex-boyfriend. She said she doesn't remember the connection between the fight and the game except that maybe “in my deepest, darkest soul I would quite like to see him hit by a bludger.”",
            "The Harry Potter movies could have been a lot different. Before an argument with Rowling, Steven Spielberg was supposed to direct the first movie, and he wanted Haley Joel Osment to play Harry.",
            "Rowling was inspired by a real-life bookstore in Porto, Portugal called Livraria Lello, which she lived near for a few years. Many say the bookstore reminds them of Flourish and Blotts.",
            "Peeves, the poltergeist in the books, was supposed to appear in the films played by British comedian and actor Rik Mayall, but director Chris Columbus didn't like how the character looked onscreen and cut him.",
            "In the books, Hermione had buck teeth, and she almost did in the movie too. Emma Watson first wore fake buck teeth, but when the crew realized she couldn't speak properly with them in her mouth, they decided to take them out.",
            "In Harry Potter and the Order of the Phoenix, Arthur Weasley was originally supposed to die, but his death was replaced with the death of Sirius.",
            "In Prisoner of Azkaban, Professor Trelawney refused to sit at a table with 12 other characters because she would be the 13th and the first one to get up after that would die. In Order of the Phoenix, 13 members of the order are sitting and Sirius was the first one to stand up.",
            "The names of the plants in the wizarding world are based on real names. Rowling said that theyre from a book called Culpeper's Complete Herbal, which was written in the 17th century by English botanist and herbalist Nicholas Culpeper."
        ];

        document.getElementById('fact').innerHTML = facts[Math.floor(Math.random() * facts.length)];
    }
    

    render() { 
      return (
        <ThemeProvider theme={darkTheme}>
            <>
            <GlobalStyles />
            <div className="columns">
            <a href="https://reduc.io" className='is-hidden-desktop'><h4 className='logo-top bottom-margin-sm'>Reduc.<span className="fact-button">io </span></h4></a>
                <div className="column is-12 top-padding-xl mobile-top-padding center">
                    <h1>I can't find that link 😔</h1>
                    <div className="top-padding-lg">
                        <div>
                            <button onClick={this.newFact} className="fact-button highlight-text">Fun fact</button>
                            <div className='fact-container center top-margin'>
                                <p id="fact" className="facts"></p>
                            </div>
                        </div>
                    </div>
                    <a href="https://reduc.io" className='is-hidden-touch'><h4 className='logo-bottom bottom-margin-sm'>Reduc.<span className="fact-button">io </span></h4></a>
                </div>
            </div>
            <p id="themeID"></p>        
            </>
        </ThemeProvider>
      );
    }
}