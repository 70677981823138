export const lightTheme = {
    body: '#e9e8ec',
    text: '#000',
    inputBackground: '#FAFAFA',
    modeBackgroundHover: '#fff',
    inputBorder: '2px solid #8789a7',
    leftBody: '#e9e8ec',
    leftShadow: '#FFF',
    rightBody: '#e3e0ea',
    rightColor: '',
    inputShadow: '0PX 4PX 3PX RGBA(0,0,0,0.2)',
    inputShadowHover: '0PX 16PX 10PX RGBA(0,0,0,0.14)',
    modeShadowHover: '0PX 6PX 6PX RGBA(0,0,0,0.2)',
    mode:'#EEE',
    modeColor: '#777',
    modeHover: '#EEE',
    instructionColor: '#a9a9b9',
    instructionBorder: '2px solid #bcbcca',
    submitButton: '#999',
    submitButtonReady: '#8789a7',
    submitButtonReadyHover: '#f1f1f1 ',
    castleLightDisplay: 'initial',
    castleDarkDisplay: 'none',
    footerColor: 'rgba(0,0,0,0.08)',
    //rightBackground: 'lightCastle',
    highlighter: 'linear-gradient(to bottom, transparent 84%, #757cff 40%);',
    highlightedText: '#FFF',
    backgroundHighlightedText: '#757cff'
  }
  
  export const darkTheme = {
    body: '#2A2D32',
    text: '#fff',
    modeBackgroundHover: '#222',
    inputBorder: '2px solid #999',
    leftBody: '#2A2D32',
    leftShadow: '#FFF',
    rightBody: '#212227',
    rightColor: '',
    inputBackground: '#32363D',
    inputShadow: '0PX 4PX 3PX RGBA(0,0,0,0.14)',
    inputShadowHover: '0PX 16PX 10PX RGBA(0,0,0,0.14)',
    modeShadowHover: '0PX 6PX 6PX RGBA(0,0,0,0.2)',
    mode:'rgba(255, 255, 255, 0.12)',
    modeColor: '#999',
    modeHover: 'rgba(255, 255, 255, 0.2)',
    instructionColor: "#666",
    instructionBorder: '2px solid rgba(255, 255, 255, 0.2)',
    submitButton: 'rgba(255, 255, 255, 0.3)',
    submitButtonReady: '#FFF',
    submitButtonReadyHover: '#f1f1f1 ',
    castleLightDisplay: 'none',
    castleDarkDisplay: 'initial',
    footerColor: 'rgba(255,255,255,0.08)',
    //rightBackground: 'darkCastle',
    highlighter: 'linear-gradient(to bottom, transparent 84%, #757cff 40%);',
    highlightedText: '#FFF',
    backgroundHighlightedText: '#757cff'
  }

  export const harryTheme = {
    text: '#fff',
    highlightedText: '#9074FF',
    inputBackground: '#111',
    inputShadow: '0px 6px 6px rgba(0, 0, 0, 0.25), 0px 0px 32px rgba(255, 184, 0, 0.15)',
    inputShadowHover: '0px 10px 12px rgba(0, 0, 0, 0.25), 0px 0px 48px rgba(255, 184, 0, 0.20)'
  }