import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './Components/main';
import NotFound from './Components/404';
import oops from './Components/oops';

export default class App extends React.Component {
  render(){
      return(
          <Router>
              <Switch>                
                <Route exact path='/' component={Main} />
                <Route path="/404" component={oops} />
                <Route path="*" component={NotFound} />
              </Switch>
          </Router>
      )
  }
}