import React from 'react';
import '../css/main.css';
import '../css/bulma.css';
import Nav from './nav';
import Header from './header';
import URLInput from './urlInput';
import Footer from './footer';

class Body extends React.Component {
    render() { 
      return (
        <div>
          <Nav />
          <div className="main-body">
            <Header />
            <URLInput />
            <Footer />
          </div>
        </div>
      );
    }
}

export default Body;

  