import React from 'react';
import axios from 'axios';
import '../css/main.css';
import '../css/bulma.css';
import getLink from '../server/getLink.php';

export default class NotFound extends React.Component {

    componentDidMount() {
      /*
      Get submitted url ("https://reduc.io/XXXXXX" or "reduc.io/XXXXXX")
      Send url
      Recieve original url
      Redirect to original url
      */
      console.log("im alive:");
      var url = window.location.href;
      var pattern = /(http:\/\/reduc.io)\/...*$|(https:\/\/reduc.io)\/...*$|(reduc.io)\/...*$|(http:\/\/localhost:3000)\/...*$/;
      
      if (pattern.test(url)) {
          //get has value
          var hash = url.split("/").pop();
          console.log("finding: " + hash);
          //send to php to find the right url
          axios.get(getLink, {
            params: {
              url: hash
            }
          })
          .then(res => {
            //redirect to original url
            var url = res.data;

            if(url) {
              console.log("found! " + url);
              window.location.replace(url);
            } else {
              console.log("Nothing was found");
              window.location.replace('https://reduc.io/404');
            }
            
          });
      } else {
        window.location.replace('https://reduc.io/404');
      }
          
    }

    render() { 
      return (
        <div>
          <div className="body-404">
          </div>
        </div>
      );
    }
}

  