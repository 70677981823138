// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import { createGlobalStyle } from 'styled-components';
//import lightCastle from './img/castle-light.png';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  h1 {
    font-size: 64px;
    line-height: 1.1;
  }

  h4 {
    font-size: 24px;
  }

  .small-nav-text {
    font-size: 16px;
  }

  body {
    align-items: center;
    color: ${({ theme }) => theme.text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    transition: 0.2s;
  }
  
  input {
    background: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.text};
    box-shadow: ${({ theme }) => theme.inputShadow};
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -ms-transition: 0.25s;
    -o-transition: 0.25s;
  }

  input:hover {
    box-shadow: ${({ theme }) => theme.inputShadowHover};
  }

  input:focus {
    box-shadow: ${({ theme }) => theme.inputShadowHover};
  }

  input:focus::-webkit-input-placeholder {
    color: #222;
  }

  input:focus:-moz-placeholder {
    color: #222;
  }

  input:focus::-moz-placeholder {
    color: #222;
  }

  input:focus:-ms-input-placeholder {
    color: #222;
  }

  .semi-translucent {
    opacity: 0.4;
  }

  .copyBtn {
    background: #6367FF;
    color: #FFF;
    box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.15);
  }
  
  .right-side {
    background: ${({ theme }) => theme.rightBody};
    height: 100vh;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1000;
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -ms-transition: 0.25s;
    -o-transition: 0.25s;
  }
  
  .left-side {
    position: absolute;
    background: ${({ theme }) => theme.leftBody};
    top: 0;
    left: 0;
    height: 100vh;
    padding: 0px 12%;
    padding-top: 10%;
    z-index: 1000;
    box-shadow: 0px 0px 80px rgba(0,0,0,0.15);
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -ms-transition: 0.25s;
    -o-transition: 0.25s;
  }
  
  .right-content {
    padding: 32% 16%;
    background-image: url(${({ theme }) => theme.rightBackground});
  }
  
  .type-button {
    background-color: ${({ theme }) => theme.mode};
    color: ${({ theme }) => theme.modeColor};
  }
  
  .type-button:hover {
    background-color: ${({ theme }) => theme.modeHover};
    color: ${({ theme }) => theme.modeColor};
  }

  .theme-button {
    min-width: 60px;
    min-height: 60px;
    border-radius: 200px;
    font-size: 24px;
    background-color: ${({ theme }) => theme.inputBackground};
    box-shadow:  ${({ theme }) => theme.inputShadow};
    color: ${({ theme }) => theme.modeColor};
  }
  
  .theme-button:hover {
    background-color: ${({ theme }) => theme.modeBackgroundHover};
    box-shadow:  ${({ theme }) => theme.modeShadowHover};
    color: ${({ theme }) => theme.modeColor};
  }

  .theme-button:focus {
    outline: none !important;
  }

  .theme-button:active {
    outline: none !important;
  }
  
  .inputInstructionsButton {
    border: ${({ theme }) => theme.instructionBorder};
  }

  .submit-button {
    color: ${({ theme }) => theme.inputBackground} !important;
  }
  
  .submit-button-ready {
    color: ${({ theme }) => theme.inputBackground};
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }

  .submit-button-ready:hover {
    color: ${({ theme }) => theme.submitButtonReadyHover};
  }

  .copy-button {
    color: #000;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }
  
  .castleLight {
    display: ${({ theme }) => theme.castleLightDisplay};
  }

  .castleDark {
    display: ${({ theme }) => theme.castleDarkDisplay};
  }
  
  .highlighter {
    background-image: ${({ theme }) => theme.highlighter};
  }
  
  .highlight-text {
    color: ${({ theme }) => theme.highlightedText};
  }`